<template>
  <div>
    <div v-if="addView" class="header-text pb-3" data-cy="header-add-Employers">
      Add Employer
    </div>
    <div v-else class="header-text pb-3" data-cy="header-edit-Employer">
      Edit {{ employer.employer_name }}
    </div>
    <div class="box-style">
      <div v-if="loading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <ErrorBanner
          v-if="errorMessage.length > 0"
          title="Errors in input Employer"
          :messages="errorMessage"
          error
        />
        <employer-edit-form v-model="employer" />
      </div>
    </div>

    <div class="text-right pt-3">
      <router-link to="/admin/sysadmin/employers/" class="px-5">
        <Button text="Cancel" />
      </router-link>

      <Button text="Save" @click="submitForm" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/reusable-components/base-level-components/Button";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { post, get, put } from "@/composables/httpUtil";
import EmployerEditForm from "@/components/reusable-components/EmployerEditForm";
import { mapActions } from "vuex";
import ErrorBanner from "@/components/forms/ErrorBanner";

export default {
  name: "AddEmployers",
  components: {
    ErrorBanner,
    EmployerEditForm,
    Button,
    LoadingSpinner,
  },
  created() {
    Promise.all([this.setup_employer(), this.fetchLookupResource()]);
  },
  data() {
    return {
      loading: false,
      addView: true,
      editEmployer: undefined,
      employerId: "",
      employer: {
        employer_name: null,
        web_site: null,
        number_employees: null,
        display_employer: true,
        address: {
          address_id: null,
          address_type: "WORK",
          address_line_one: null,
          address_line_two: null,
          city: null,
          state: null,
          zip_code: null,
          is_primary: true,
          is_public: true,
        },
        phone: {
          phone_type: "WORK",
          phone_number: null,
          is_public: true,
          is_primary: true,
        },
      },
      errorMessage: [],
    };
  },
  watch: {
    employer: {
      handler(employer) {
        this.validateForm(employer);
      },
      deep: true,
    },
  },
  methods: {
    get,
    put,
    post,
    ...mapActions(["fetchLookupResource"]),
    setup_employer() {
      return new Promise((resolve, reject) => {
        if (this.$route.fullPath.includes("add")) {
          this.addView = true;
        } else {
          this.addView = false;
          this.loading = true;
          this.employerId = this.$route.params.id;
          this.get("admin/employers/" + this.employerId)
            .then((results) => {
              this.employer = results;
            })
            .then(() => resolve())
            .catch((error) => {
              reject(error);
            })
            .finally(() => (this.loading = false));
        }
        resolve();
      });
    },
    submitForm() {
      if (this.validateForm(this.employer)) {
        this.loading = true;
        if (this.addView) {
          this.post("admin/employers", this.employer)
            .then((response) => {
              //success
              this.loading = false;

              this.$router.push(
                "/admin/sysadmin/employers/view/" + response.employer_id
              );
            })
            .catch((error) => {
              console.log("error: (submitForm/addView):", error);
              this.loading = false;
            });
        } else {
          this.put("admin/employers/" + this.employerId, this.employer)
            .then((response) => {
              //success
              this.loading = false;

              this.$router.push(
                "/admin/sysadmin/employers/view/" + response.employer_id
              );
            })
            .catch((error) => {
              console.log("error: (submitForm):", error);
            })
            .finally(() => (this.loading = false));
        }
      }
    },
    validateForm(employer) {
      this.errorMessage = [];

      if (this.employer_name) {
        this.errorMessage.push("Please enter an employer name");
      }
      if (!employer?.phone?.phone_number) {
        this.errorMessage.push("Please enter a phone number");
      }
      if (!employer?.address?.address_line_one) {
        this.errorMessage.push("Please enter at least one address");
      }
      if (!employer?.address?.state) {
        this.errorMessage.push("Please select a state");
      }
      if (!employer?.address?.city) {
        this.errorMessage.push("Please enter a city");
      }
      if (!employer?.address?.zip_code) {
        this.errorMessage.push("Please enter a zip code");
      }
      return this.errorMessage.length === 0;
    },
    resetForm() {
      this.employer = {
        employer_name: null,
        web_site: null,
        number_employees: null,
        display_employer: true,
        address: {
          address_id: null,
          address_type: "WORK",
          address_line_one: null,
          address_line_two: null,
          city: null,
          state: null,
          zip_code: null,
          is_primary: true,
          is_public: true,
        },
        phone: {
          phone_type: "WORK",
          phone_number: null,
          is_public: true,
          is_primary: true,
        },
      };

      this.errorMessage = [];
    },
  },
};
</script>

<style scoped></style>
