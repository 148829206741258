<template>
  <form>
    <div class="text-2xl text-dark-purple font-semibold mb-2">
      Employer Details
    </div>
    <div class="px-3">
      <div class="mb-2">
        <label for="textbox-name">Name </label>
      </div>
      <input
        id="textbox-name"
        type="text"
        class="w-full rounded border border-steel-gray mb-3"
        v-model.trim="employer.employer_name"
        maxlength="500"
      />
      <div class="mb-2">
        <label for="textbox-number-of-employees">Number of Employees</label>
      </div>
      <input
        id="textbox-number-of-employees"
        type="number"
        class="w-full rounded border border-steel-gray mb-3"
        v-model="employer.number_employees"
        min="0"
      />
      <div class="mb-2">
        <label for="textbox-website">Website</label>
      </div>
      <input
        id="textbox-website"
        type="text"
        class="w-full rounded border border-steel-gray mb-3"
        v-model.trim="employer.web_site"
        maxlength="500"
      />
    </div>

    <div class="text-2xl text-dark-purple font-semibold mb-2">
      Employer Contact
    </div>
    <div class="px-3">
      <div class="mb-2">
        <label for="textbox-employer-phone">Phone</label>
      </div>
      <input
        id="textbox-employer-phone"
        type="tel"
        class="w-full rounded border border-steel-gray mb-3"
        v-model.trim="employer.phone.phone_number"
        maxlength="200"
        v-on:keyup="
          employer.phone.phone_number = formatPhoneNumber(
            employer.phone.phone_number
          )
        "
      />
      <div class="mb-2">
        <label for="textbox-address">Address 1</label>
      </div>
      <input
        id="textbox-address"
        type="text"
        class="w-full rounded border border-steel-gray mb-3"
        v-model.trim="employer.address.address_line_one"
        maxlength="200"
      />
      <div class="mb-2">
        <label for="textbox-employer-address2">Address 2</label>
      </div>
      <input
        id="textbox-employer-address2"
        type="text"
        class="w-full rounded border border-steel-gray mb-3"
        v-model.trim="employer.address.address_line_two"
        maxlength="200"
      />
      <div class="mb-2">
        <label for="textbox-employer-city">City</label>
      </div>
      <input
        id="textbox-employer-city"
        type="text"
        class="w-full rounded border border-steel-gray mb-3"
        v-model.trim="employer.address.city"
        maxlength="200"
      />
      <div class="mb-2">
        <label for="dropdownlist-states">State</label>
      </div>
      <select
        id="dropdownlist-states"
        v-model="employer.address.state"
        class="py-2 px-3 w-full rounded border border-steel-gray mb-3"
      >
        <option
          v-for="(state, state_id) in lookup_states"
          :value="state_id"
          :key="state_id"
          class="h-8"
        >
          {{ state }}
        </option>
      </select>
      <div class="mb-2">
        <label for="textbox-employer-zip">Zip Code</label>
      </div>
      <input
        id="textbox-employer-zip"
        type="text"
        class="w-full rounded border border-steel-gray mb-3"
        v-model.trim="employer.address.zip_code"
        maxlength="20"
        v-on:keyup="
          employer.address.zip_code = cleanZip(employer.address.zip_code)
        "
      />
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EmployerEditForm",
  props: {
    modelValue: {
      type: Object,
    },
  },
  data() {
    return {
      employer: {
        employer_name: null,
        web_site: null,
        number_employees: null,
        display_employer: true,
        address: {
          address_id: null,
          address_type: "WORK",
          address_line_one: null,
          address_line_two: null,
          city: null,
          state: null,
          zip_code: null,
          is_primary: true,
          is_public: true,
        },
        phone: {
          phone_type: "WORK",
          phone_number: null,
          is_public: true,
          is_primary: true,
        },
      },
    };
  },
  watch: {
    employer: {
      handler(newValue) {
        this.$emit("update:modelValue", newValue);
      },
      deep: true,
    },
  },
  created() {
    this.employer = { ...this.modelValue };
    if (this.employer.address === null) {
      this.employer.address = {
        address_id: null,
        address_type: "WORK",
        address_line_one: null,
        address_line_two: null,
        city: null,
        state: null,
        zip_code: null,
        is_primary: true,
        is_public: true,
      };
    }
    if (this.employer.phone === null) {
      this.employer.phone = {
        phone_type: "WORK",
        phone_number: null,
        is_public: true,
        is_primary: true,
      };
    }
  },
  methods: {
    formatPhoneNumber(phone_number) {
      // if input value is falsy eg if the user deletes the input, then just return
      if (!phone_number) return phone_number;

      // clean the input for any non-digit values.
      const phoneNumber = phone_number.replace(/[^\d]/g, "");
      this.phone = phoneNumber;
      // phoneNumberLength is used to know when to apply our formatting for the phone number
      const phoneNumberLength = phoneNumber.length;

      // we need to return the value with no formatting if its less than four digits
      // this is to avoid weird behavior that occurs if you  format the area code too early
      if (phoneNumberLength < 4) return phoneNumber;

      // if phoneNumberLength is greater than 4 and less the 7 we start to return
      // the formatted number
      if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
      }

      // finally, if the phoneNumberLength is greater then seven, we add the last
      // bit of formatting and return it.
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    },
    cleanZip(zip) {
      return zip?.replace(/[^\d-]/g, "");
    },
  },
  computed: {
    ...mapGetters(["lookup_states"]),
  },
};
</script>

<style scoped></style>
